import styled from "styled-components"

const Container = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  h2 {
    font-size: 1.5rem;
  }

  > section {
    margin: 1rem 0;
  }
`

const Row = styled.section`
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
`

const Column = styled.section`
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;

  > img {
      min-width: 60%;
      width: 80%;
  }
`

const Box = styled(Column)`
  justify-content: center;
  align-items: center;
  text-align: center;
  label {
    font-size: 1rem;
  }
  p {
    color: #363636;
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.125;
  }
`

const ImageContainer = styled(Row)`
  align-items: center;
  width: 100%;
  > div > img {
    padding: 0.5rem;
    max-height: 16rem;
  }
  > div {
    box-sizing: border-box;
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
`

export { Container, Row, Column, Box, ImageContainer }
