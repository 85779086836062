import React from "react"
import { Container, Column, Row, Box, ImageContainer } from "./dashboard.style"

const Dashboard = () => {
  return (
    <Container>
      <Column>
        <h2>Number of Students</h2>
        <Row>
          <Box>
            <label>Undergraduate Student</label>
            <p>19525</p>
          </Box>
          <Box>
            <label>Graduate Student</label>
            <p>3106</p>
          </Box>
        </Row>
      </Column>
      <ImageContainer>
        <div>
          <img src="https://firebasestorage.googleapis.com/v0/b/sustainable-kmitl.appspot.com/o/images%2Feducation%2Feducation-1.jpg?alt=media&token=fb1750b0-ca7f-4819-9718-648245084eb0" alt={"education-1"}/>
        </div>
        <div>
          <img src="https://firebasestorage.googleapis.com/v0/b/sustainable-kmitl.appspot.com/o/images%2Feducation%2Feducation-2.jpg?alt=media&token=dcb62646-268f-455c-a917-f03953428917" alt={"education-2"}/>
        </div>
      </ImageContainer>
      <Column>
        <h2>Number of Graduates</h2>
        <Row>
          <Box>
            <label>Bachelor</label>
            <p>5115</p>
          </Box>
          <Box>
            <label>Master's</label>
            <p>600</p>
          </Box>
          <Box>
            <label>Doctoral</label>
            <p>100</p>
          </Box>
          <Box>
            <label>Total</label>
            <p>5815</p>
          </Box>
        </Row>
      </Column>
      <Column>
        <img src="https://firebasestorage.googleapis.com/v0/b/sustainable-kmitl.appspot.com/o/images%2Feducation%2Feducation-3.jpg?alt=media&token=960f35dc-6e14-4cd0-9092-00a368873f26" alt={"education-3"}/>
      </Column>

      <Column>
        <h2>Number of Staff</h2>
        <Row>
          <Box>
            <label>Academic Staff</label>
            <p>1114</p>
          </Box>
          <Box>
            <label>Support Staff</label>
            <p>1024</p>
          </Box>
        </Row>
      </Column>
      <Column>
        <img src="https://firebasestorage.googleapis.com/v0/b/sustainable-kmitl.appspot.com/o/images%2Feducation%2Feducation-4.jpg?alt=media&token=f5868e54-ac8e-4f04-a1b8-6bf98b4fee74" alt={"education-4"}/>
      </Column>

      <Column>
        <h2>Number of courses</h2>
        <Row>
          <Box>
            <label>Undergraduate</label>
            <p>3342</p>
          </Box>
          <Box>
            <label>Graduate</label>
            <p>284</p>
          </Box>
          <Box>
            <label>Total</label>
            <p>3626</p>
          </Box>
        </Row>
      </Column>

	  <Column>
		<Box>
			<label>Sustainability Courses</label>
			<p>606</p>
		</Box>
		  <Box>
			  <label>Average of research fund in last 3 years</label>
			  <p>3,651,228 US Dollars</p>
		  </Box>
		  <Box>
			  <label>Average of sustainability research fund in last 3 years</label>
			  <p>1,711,144 US Dollars</p>
		  </Box>
		  <Box>
			  <label>Average of research fund dedicated to sustainability research in last 3 years</label>
			  <p>44.61 US Dollars</p>
		  </Box>

	  </Column>

    </Container>
  )
}

export default Dashboard
