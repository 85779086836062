import React from 'react';
import SEO from '../../components/seo';
import Layout from '../../components/layout';
import Dashboard from  "../../components/dashboard/dashboard.component";

const EducationAndResearchPage = () => {
	return (
		<Layout>
			<SEO title={"Education and Research"}/>
			<h1 className={"title"}>Education and Research</h1>
			<Dashboard/>
		</Layout>
	)
};

export default EducationAndResearchPage;
